// /src/components/SuccessPage.js
import React from 'react';

const SuccessPage = () => {
  const phoneNumber = localStorage.getItem('phoneNumber');

  return (
    <div className="container">
      <h1>Account Deleted Successfully</h1>
      <p>Your account associated with <strong>{phoneNumber}</strong> has been successfully deleted.</p>
    </div>
  );
};

export default SuccessPage;
