// /src/components/OtpInput.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import './OtpInput.css'; // Import CSS for styling

const OtpInput = () => {
  const [otpDigits, setOtpDigits] = useState(Array(6).fill('')); // Initialize 6 empty digits
  const inputRefs = useRef([]); // Refs to focus each input programmatically
  const navigate = useNavigate();
  const phoneNumber = localStorage.getItem('phoneNumber');
  const verifyToken = localStorage.getItem('verifyToken'); // Retrieve verifyToken from localStorage

  // Handle each individual digit change and move focus automatically
  const handleDigitChange = (e, index) => {
    const value = e.target.value;

    // Only allow digits
    if (/\D/.test(value) || value.length > 1) return;

    const updatedDigits = [...otpDigits];
    updatedDigits[index] = value;

    setOtpDigits(updatedDigits);

    // Automatically move to the next input if there's a value
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Handle backspace to move to the previous input box if empty
  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otpDigits[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleConfirm = async () => {
    const otp = otpDigits.join(''); // Concatenate the digits

    if (otp.length === 6) { // Ensure full OTP is entered
      try {
        // Make DELETE request to delete account
        const response = await axios.delete('https://api-mobile.alskom.uz/api/v1/user/web', {
          headers: {
            'accept': '*/*',
            'Content-Type': 'application/json',
          },
          data: {
            verificationToken: verifyToken, // Use the stored verifyToken
            otp: otp, // Send the entered OTP
          },
        });

        console.log(response.data); // Log the response for debugging
        navigate('/success'); // Navigate to success page
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message); // Log the error response
        alert('Invalid OTP. Please try again.');
      }
    } else {
      alert('Please enter a valid 6-digit OTP.');
    }
  };

  return (
    <div className="container">
      <h1>Verify Phone Number</h1>
      <p>We have sent an OTP to <strong>{phoneNumber}</strong>. Please enter the OTP below:</p>
      <div className="otp-input-container">
        {otpDigits.map((digit, index) => (
          <input
            key={index}
            type="tel"  // Change type to "tel" to bring up number keypad
            value={digit}
            onChange={(e) => handleDigitChange(e, index)}
            onKeyDown={(e) => handleBackspace(e, index)}
            maxLength={1}  // Only allow one digit per input box
            ref={(el) => (inputRefs.current[index] = el)}  // Save reference to each input
            className="otp-input"
            required
            pattern="[0-9]*"  // Restrict input to numbers on iOS
            style={{ width: '40px', textAlign: 'center', marginRight: '5px' }}  // Add inline styling for consistency
          />
        ))}
      </div>
      <button className="btn next" onClick={handleConfirm}>
        Confirm OTP
      </button>
    </div>
  );
  
};

export default OtpInput;
