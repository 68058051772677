// // /src/components/PhoneInput.js
// import React, { useState, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import logo from '../files/logo.jpg'; // Import the image
// import axios from 'axios'; // Import axios for API calls
// import './PhoneInput.css'; // Adjust the path if necessary

// const PhoneInput = () => {
//   const [phoneDigits, setPhoneDigits] = useState(Array(9).fill('')); // Initialize 9 empty digits
//   const inputRefs = useRef([]); // Refs to focus each input programmatically
//   const navigate = useNavigate();

//   // Handle each individual digit change and move focus automatically
//   const handleDigitChange = (e, index) => {
//     const value = e.target.value;

//     // Only allow digits
//     if (/\D/.test(value)) return;

//     const updatedDigits = [...phoneDigits];
//     updatedDigits[index] = value;

//     setPhoneDigits(updatedDigits);

//     // Automatically move to the next input if there's a value
//     if (value && index < 8) {
//       inputRefs.current[index + 1].focus();
//     }
//   };

//   // Handle backspace to move to the previous input box if empty
//   const handleBackspace = (e, index) => {
//     if (e.key === 'Backspace' && index > 0 && phoneDigits[index] === '') {
//       inputRefs.current[index - 1].focus();
//     }
//   };

//   const handleNext = async () => {
//     const phoneNumber = '998' + phoneDigits.join(''); // Concatenate the digits with +998 prefix

//     try {
//       // Step 1: Request OTP verification
//       const response = await axios.post(
//         'http://localhost:8085/api/v1/user/web/deletion/otp',
//         {
//           phoneNumber: phoneNumber, // Send the full phone number
//         },
//         {
//           headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//           },
//           timeout: 10000, // Set timeout to 10 seconds
//         }
//       );

//       console.log(response.data); // Log the response for debugging

//       // Check if the response contains the expected data structure
//       if (response.data && response.data.data && response.data.data.verifyToken) {
//         const { verifyToken } = response.data.data; // Destructure verifyToken from the response

//         // Save phone number and verifyToken to localStorage
//         localStorage.setItem('phoneNumber', phoneNumber); // Save full phone number with prefix
//         localStorage.setItem('verifyToken', verifyToken); // Save the verifyToken for OTP confirmation

//         // Step 2: Navigate to the OTP page
//         navigate('/otp'); // Navigate to the OTP page
//       } else {
//         throw new Error('Invalid response structure'); // Throw an error if structure is unexpected
//       }
//     } catch (error) {
//       // Handle network and other errors
//       if (error.response) {
//         console.error('Error response:', error.response.data); // Log the error response
//         alert(`Error: ${error.response.data.message || 'Error requesting OTP. Please try again.'}`);
//       } else if (error.request) {
//         console.error('Error request:', error.request); // Log the request
//         alert('Network error: Unable to reach the server. Please check your connection.');
//       } else {
//         console.error('Error:', error.message); // Log the error message
//         alert('Error: ' + error.message);
//       }
//     }
//   };

//   return (
//     <div className="PhoneContainer">
//       <h1 className="PhoneApp-title">Alskom</h1>
//       <img 
//         src={logo} 
//         alt="Delete Account" 
//         className="logo"
//       />
//       <h1 className="header">Delete Account</h1>
//       <p className="description">Enter your phone number to receive an OTP for verification:</p>

//       {/* Display the phone number input as separate boxes */}
//       <div className="input-container">
//         <span className="prefix">+998</span>
//         {phoneDigits.map((digit, index) => (
//           <input
//             key={index}
//             type="text"
//             value={digit}
//             onChange={(e) => handleDigitChange(e, index)}
//             onKeyDown={(e) => handleBackspace(e, index)}
//             maxLength={1}  
//             ref={(el) => (inputRefs.current[index] = el)}  
//             className="digit-input"
//             required
//           />
//         ))}
//       </div>

//       <button className="btn next" onClick={handleNext}>
//         Next
//       </button>
//     </div>
//   );
// };

// export default PhoneInput;


// /src/components/PhoneInput.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../files/logo.jpg'; // Import the image
import axios from 'axios'; // Import axios for API calls
import './PhoneInput.css'; // Adjust the path if necessary

const PhoneInput = () => {
  const [phoneDigits, setPhoneDigits] = useState(Array(9).fill('')); // Initialize 9 empty digits
  const inputRefs = useRef([]); // Refs to focus each input programmatically
  const navigate = useNavigate();

  // Handle each individual digit change and move focus automatically
  const handleDigitChange = (e, index) => {
    const value = e.target.value;

    // Only allow digits
    if (/\D/.test(value)) return;

    const updatedDigits = [...phoneDigits];
    updatedDigits[index] = value;

    setPhoneDigits(updatedDigits);

    // Automatically move to the next input if there's a value
    if (value && index < 8) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Handle backspace to move to the previous input box if empty
  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && phoneDigits[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleNext = async () => {
    const phoneNumber = '998' + phoneDigits.join(''); // Concatenate the digits with +998 prefix

    try {
      // Step 1: Request OTP verification
      const response = await axios.post(
        'https://api-mobile.alskom.uz/api/v1/user/web/deletion/otp',
        {
          phoneNumber: phoneNumber, // Send the full phone number
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          timeout: 10000, // Set timeout to 10 seconds
        }
      );

      console.log(response.data); // Log the response for debugging

      // Check if the response contains the expected data structure
      if (response.data && response.data.data && response.data.data.verifyToken) {
        const { verifyToken } = response.data.data; // Destructure verifyToken from the response

        // Save phone number and verifyToken to localStorage
        localStorage.setItem('phoneNumber', phoneNumber); // Save full phone number with prefix
        localStorage.setItem('verifyToken', verifyToken); // Save the verifyToken for OTP confirmation

        // Step 2: Navigate to the OTP page
        navigate('/otp'); // Navigate to the OTP page
      } else {
        throw new Error('Invalid response structure'); // Throw an error if structure is unexpected
      }
    } catch (error) {
      // Handle network and other errors
      if (error.response) {
        console.error('Error response:', error.response.data); // Log the error response
        alert(`Error: ${error.response.data.message || 'Error requesting OTP. Please try again.'}`);
      } else if (error.request) {
        console.error('Error request:', error.request); // Log the request
        alert('Network error: Unable to reach the server. Please check your connection.');
      } else {
        console.error('Error:', error.message); // Log the error message
        alert('Error: ' + error.message);
      }
    }
  };

  return (
    <div className="PhoneContainer">
      <h1 className="PhoneApp-title">Alskom</h1>
      <img 
        src={logo} 
        alt="Delete Account" 
        className="logo"
      />
      <h1 className="header">Delete Account</h1>
      <p className="description">Enter your phone number to receive an OTP for verification:</p>

      {/* Display the phone number input as separate boxes */}
      <div className="input-container">
        <span className="prefix">+998</span>
        {phoneDigits.map((digit, index) => (
          <input
            key={index}
            type="tel"  // Use "tel" to open number keypad on mobile
            value={digit}
            onChange={(e) => handleDigitChange(e, index)}
            onKeyDown={(e) => handleBackspace(e, index)}
            maxLength={1}  // Only allow one digit per input box
            ref={(el) => (inputRefs.current[index] = el)}  // Save reference to each input
            className="digit-input"
            required
            pattern="[0-9]*"  // Restrict input to numbers on iOS
          />
        ))}
      </div>

      <button className="btn next" onClick={handleNext}>
        Next
      </button>
    </div>
  );
};

export default PhoneInput;
