// /src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PhoneInput from './components/PhoneInput';
import OtpInput from './components/OtpInput';
import SuccessPage from './components/SuccessPage';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<PhoneInput />} />
          <Route path="/otp" element={<OtpInput />} />
          <Route path="/success" element={<SuccessPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
